const appConfig = {
    apiPrefix: 'https://gateway.motforex.com/api/v1',
    utPrefix: 'https://api.motforex.com/backoffice/v1',
    utPartnerPrefix: 'https://api.motforex.com/partner/v1/backoffice',
    motfundsPrefix: 'https://api.motfunds.com/api/motforex',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
